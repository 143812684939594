import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, LOCALE_ID } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient } from "@angular/common/http";
import localeUS from "@angular/common/locales/en";
import localeUSExtra from "@angular/common/locales/extra/en";
import localeUK from "@angular/common/locales/en-GB";
import localeUKExtra from "@angular/common/locales/extra/en-GB";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DD_MM_YYYY_Format } from "./common/constants";
import { MatToolbarModule } from "@angular/material/toolbar";
// import { MatButtonModule } from "@angular/material/button";

import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule } from "@angular/material/core";
import { MatPaginatorModule as MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatRippleModule } from "@angular/material/core";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule as MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { MatListModule } from "@angular/material/list";
import { DateAdapter } from "@angular/material/core";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxPaginationModule } from "ngx-pagination";
import { FileUploadModule } from "ng2-file-upload";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { PreferencesService } from "./svc/preferences.service";
import { NgClickOutsideDirective } from "ng-click-outside2";
import { DatatableResizeWatcherDirective } from "./components/datatable/datatable-resize-watcher.directive";
import { CustomDateAdapter } from "./common/CustomDateAdapter";
import { HeaderComponent } from "./components/header/header.component";
import { FeedbackComponent } from "./components/header/feedback/feedback.component";
import { NotificationComponent } from "./components/header/notification/notification.component";
import { LoginComponent } from "./components/login/login.component";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { defineLocale } from "ngx-bootstrap/chronos";
import { enGbLocale } from "ngx-bootstrap/locale";
import { PopoverModule } from "ngx-bootstrap/popover";
import { AuthorizationInterceptor } from "./authorization.interceptor";
import { HttpDateParserInterceptor } from "./httpdate.parser.interceptor";
import { HttpMessageExternaliser } from "./http.messageExternaliser.interceptor";
import { JwtHelper } from "./svc/JwtHelper";
import { ScheduledMaintenanceInterceptor } from "./scheduled.maintenance.interceptor";
import { DragulaModule } from "ng2-dragula";
import { AngularDraggableModule } from "angular2-draggable";
import { NotificationIconComponent } from "./components/header/notification-icon/notification-icon.component";
import { PlatoAlertComponent } from "./components/plato-alert/plato-alert.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ExcelExportComponent } from "./components/excel-export/excel-export.component";
import {
  PlatoDatePipe,
  PlatoDatePipeUTC,
  PlatoEarliestTimezoneDatePipe,
  PlatoLatestTimezoneDatePipe,
  PlatoDateTimePipe,
} from "./common/plato-date-pipe";
import { PoiSearchDialogComponent } from "../home/search/search-list/poi-search/poi-search.dialog";
import { NgChartsModule } from "ng2-charts";
import { ChartComponent } from "./components/chart/chart.component";
import { ColumnTogglerComponent } from "../home/search/frame-controls/column-toggler/column-toggler.component";
import { CollapsableCardComponent } from "../core/components/collapsable-card/collapsable-card.component";
import { JoinListPipe } from "./pipes/join-list/join-list.pipe";
import { ScrollTopDirective } from "./common/directives/scroll-top.directive";
import { AssociatedFramesListComponent } from "../home/search/associated-frames-list/associated-frames-list.component";
import { TileSpinnerComponent } from "./components/tile-spinner/tile-spinner.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { SitelistValidationStatusPipe } from "../home/campaigns/edit-campaign/pipes/sitelist-validation-status.pipe";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { DeleteAllValuesPopupComponent } from "./components/delete-all-values-popup/delete-all-values-popup.component";
import { CustomPoiDialogComponent } from "../home/search/navbar/poi-inclusion-exclusion-component/poi-search-component/custom-poi-dialog/custom-poi-dialog.component";
import { QuillModule } from "ngx-quill";
import { CancelBookingDialogComponent } from "../home/campaigns/campaign-grid-v3/cancel-booking-dialog/cancel-booking-dialog.component";
import { GenericPipe } from "./pipes/generic/generic.pipe";
import { IsNotEnteredPipe, FormControlRequiredPipe } from "./pipes/form-control-required/form-control-required.pipe";
import { FileSaver } from "./svc/file-saver.service";
import { RegionsComponent } from "./components/header/regions/regions.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateService } from "@ngx-translate/core";
import { ConfigurationService } from "./svc/configuration-service";
import { DatatableComponent } from "./components/datatable/datatable.component";
import { FormatImpactsPipe } from "../home/campaigns/edit-campaign/pipes/formatImpacts.pipe";
import { EditableTextCellComponent } from "../home/campaigns/media-and-costs-v3/editable-text-cell/editable-text-cell.component";
import { DialogModule } from "./components/dialog/dialog.module";
import { MatButtonModule } from "@angular/material/button";
import { CheckBoxComponent } from "./components/checkbox/checkbox.component";
import { ComponentsModule } from "./components/components.module";
import { EditNotesComponent } from "../home/campaigns/edit-notes/edit-notes.component";
import {
  ActionButtonComponent,
  ActionButtonsContainerComponent,
} from "./components/buttons/action-button/action-button.component";
import { NumberWithCommaPipe } from "./pipes/number-with-comma/number-with-comma.pipe";
import { ShortenWithSymbolSuffixPipe } from "./pipes/shorten-with-symbol-suffix/shorten-with-symbol-suffix.pipe";

registerLocaleData(localeUK, undefined, localeUKExtra);
registerLocaleData(localeUS, undefined, localeUSExtra);

function HttpLoaderFactory(httpHandler: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(httpHandler));
}
@NgModule({
  imports: [
    DeleteAllValuesPopupComponent,
    SpinnerComponent,
    TileSpinnerComponent,
    NgClickOutsideDirective,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxDatatableModule,
    NgxPaginationModule,
    NgxMatSelectSearchModule,
    CdkTableModule,
    CdkTreeModule,
    MatExpansionModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDividerModule,
    MatGridListModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatTreeModule,
    MatDialogModule,
    FileUploadModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    AngularDraggableModule,
    InfiniteScrollModule,
    NgChartsModule,
    DragulaModule,
    NgbModule,
    NgbTooltipModule,
    DialogModule,
    ComponentsModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [["bold", "italic", "underline", "strike"], [{ list: "ordered" }, { list: "bullet" }], ["link"]],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
  ],
  declarations: [
    DatatableComponent,
    DatatableResizeWatcherDirective,
    ScrollTopDirective,
    PlatoDatePipe,
    PlatoDateTimePipe,
    PlatoDatePipeUTC,
    PlatoEarliestTimezoneDatePipe,
    PlatoLatestTimezoneDatePipe,
    JoinListPipe,
    SitelistValidationStatusPipe,
    GenericPipe,
    FormControlRequiredPipe,
    IsNotEnteredPipe,
    FormatImpactsPipe,
    NumberWithCommaPipe,
    ShortenWithSymbolSuffixPipe,
    CollapsableCardComponent,
    HeaderComponent,
    FeedbackComponent,
    NotificationComponent,
    NotificationIconComponent,
    LoginComponent,
    PoiSearchDialogComponent,
    PlatoAlertComponent,
    ExcelExportComponent,
    RegionsComponent,
    CustomPoiDialogComponent,
    CancelBookingDialogComponent,
    AssociatedFramesListComponent,
    ChartComponent,
    ColumnTogglerComponent,
    EditableTextCellComponent,
    CheckBoxComponent,
    EditNotesComponent,
    ActionButtonComponent,
    ActionButtonsContainerComponent,
  ],
  exports: [
    CommonModule,
    NgxDatatableModule,
    PopoverModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxPaginationModule,
    CdkTableModule,
    CdkTreeModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatTreeModule,
    NgxMatSelectSearchModule,
    FileUploadModule,
    BsDatepickerModule,
    AngularDraggableModule,
    NgbModule,
    NgClickOutsideDirective,
    DatatableComponent,
    DatatableResizeWatcherDirective,
    CollapsableCardComponent,
    ScrollTopDirective,
    HeaderComponent,
    FeedbackComponent,
    NotificationComponent,
    NotificationIconComponent,
    LoginComponent,
    ExcelExportComponent,
    PlatoDatePipe,
    PlatoDateTimePipe,
    PlatoDatePipeUTC,
    ChartComponent,
    ColumnTogglerComponent,
    PlatoEarliestTimezoneDatePipe,
    PlatoLatestTimezoneDatePipe,
    ChartComponent,
    JoinListPipe,
    TileSpinnerComponent,
    SpinnerComponent,
    SitelistValidationStatusPipe,
    NumberWithCommaPipe,
    ShortenWithSymbolSuffixPipe,
    DeleteAllValuesPopupComponent,
    CancelBookingDialogComponent,
    CustomPoiDialogComponent,
    GenericPipe,
    FormControlRequiredPipe,
    IsNotEnteredPipe,
    FormatImpactsPipe,
    RegionsComponent,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    TranslateModule,
    DialogModule,
    EditableTextCellComponent,
    CheckBoxComponent,
    EditNotesComponent,
    ActionButtonComponent,
    ActionButtonsContainerComponent,
  ],
  providers: [
    PreferencesService,
    FileSaver,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_Format },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpDateParserInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ScheduledMaintenanceInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpMessageExternaliser, multi: true },
    { provide: LOCALE_ID, useValue: "en-gb" },
    { provide: LOCALE_ID, useValue: "en-us" },
    JwtHelper,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { hideRequiredMarker: "true" } },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CoreModule {
  constructor(
    private localeService: BsLocaleService,
    private translateService: TranslateService,
    private configurationService: ConfigurationService
  ) {
    defineLocale("en-gb", enGbLocale);
    this.localeService.use("en-gb");
    this.translateService.addLangs(this.configurationService.getSupportedRegions());
    this.translateService.setDefaultLang(this.configurationService.getDefaultRegion());
    const currentLang =
      this.configurationService.getCurrentRegion() != null
        ? configurationService.getCurrentRegion()
        : configurationService.getDefaultRegion();
    this.translateService.use(currentLang);
  }
}
